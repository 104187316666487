'use client'

import React from 'react'
import { MediaSliderProps } from './types'
import MediaCard from './MediaCard'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import useSwiperRef from '../../hooks/useSwiperRef'

const MediaSlider = ({ content, items }: MediaSliderProps) => {
    const [paginationEl, paginationRef] = useSwiperRef<HTMLDivElement>()

    return (
        <div className="mt-16 lg:mt-24 max-w-screen">
            {content}
            <Swiper
                modules={[Pagination]}
                loop
                centeredSlides
                slidesPerView="auto"
                spaceBetween={16}
                slidesPerGroup={2}
                breakpoints={{
                    // when window width is >= 480px
                    0: {
                        centeredSlides: true,
                        slidesPerGroup: 2,
                        spaceBetween: 16,
                    },
                    768: {
                        centeredSlides: false,
                        slidesPerGroup: 3,
                        spaceBetween: 32,
                    },
                    1100: {
                        centeredSlides: false,
                        slidesPerGroup: 4,
                        spaceBetween: 32,
                    },
                }}
                pagination={{
                    el: paginationEl,
                    type: 'bullets',
                    clickable: true,
                }}
            >
                {items?.map((item, index) => (
                    <SwiperSlide className={'!w-fit !shrink'} key={`swiper_media_${index}`}>
                        <MediaCard name={item.name} image={item.image} link={item.link} />
                    </SwiperSlide>
                ))}
            </Swiper>
            <div
                className="container mt-[30px] md:mt-[52px] m-auto [&>span]:!m-[0] [&>span]:transition-all [&>span]:duration-200 [&>.swiper-pagination-bullet-active]:bg-dark [&>.swiper-pagination-bullet-active]:w-[27px] [&>.swiper-pagination-bullet-active]:rounded-10 [&>span]:w-[10px] [&>span]:h-[10px]  flex gap-[10px] !w-fit"
                ref={paginationRef}
            />
        </div>
    )
}

export default MediaSlider
