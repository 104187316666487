'use client'

import ProductCard from '../ProductCard'
import React from 'react'
import { MealSliderProps } from './types'
import SwiperComponent from '../Swiper'
import getVariantId from '../../utils/getVariantId'
import { ProductProvider } from 'web/src/lib/context/product-context'
import { useStore } from 'web/src/lib/context/store-context'

const MealSlider = ({ inBlog, meals }: MealSliderProps) => {
    const { cartSubscriptionContext } = useStore()

    return (
        <>
            <SwiperComponent
                inBlog={!!inBlog}
                content={
                    <div className="flex flex-col lg:[&>h2]:text-[40px] [&>h2]:mb-2.5 lg:[&>h2]:mb-3 [&>span]:text-base">
                        <h2>Dit wil je gewoon</h2>
                        <span>
                            Verser dan vers. Zero waste. En shock freeze. Zo gaan je vitamines nergens naartoe. Lekker
                            voor je.
                        </span>
                    </div>
                }
                slides={meals?.map(meal => (
                    <ProductProvider
                        preselectedVariantId={getVariantId(meal, 'single')}
                        product={meal}
                        key={meal.handle}
                    >
                        <ProductCard
                            inSlider
                            imageProps={{
                                sizes: '(max-width: 768px) 80vw, 20vw',
                                width: 260,
                                height: 281,
                            }}
                        />
                    </ProductProvider>
                ))}
            />
        </>
    )
}

export default MealSlider
