'use client'
import * as React from 'react'
import { useEffect, useRef } from 'react'
import { ReviewSliderProps } from './types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper'
import useSwiperRef from '../../hooks/useSwiperRef'
import classNames from 'classnames'

const ReviewSlider = ({ inBlog }: ReviewSliderProps) => {
    const reviews = [
        {
            name: 'Wiep',
            date: '23 juni 2023',
            content: (
                <>
                    <h2>Makkelijk, gezond en lekker</h2>
                    <p className="text-[16px] leading-[24px]">
                        Makkelijk besteld en volgens afspraak geleverd. Ik heb nu de eerste geproefd: heel lekker! Sweet
                        potato bowl. Fijn dat zelfs aan de milieuimpact van de verpakking is gedacht :)
                    </p>
                </>
            ),
        },
        {
            name: 'Nynke',
            date: '14 juni 2023',
            content: (
                <>
                    <h2>Goede prijs-kwaliteit</h2>
                    <p className="text-[16px] leading-[24px]">
                        Van alle boxen die ik heb besteld heeft Good Chef de beste prijs-kwaliteit. Verrassende
                        gerechten en binnen 10 minuten zit ik aan tafel.
                    </p>
                </>
            ),
        },
        {
            name: 'Demelza',
            date: '18 juni 2023',
            content: (
                <>
                    <h2>Echt geweldig lekkere maaltijden!</h2>
                    <p className="text-[16px] leading-[24px]">
                        Waanzinnig knap dat het vegan is en uit de diepvries. Ik vond de penne met pompoen echt
                        mindblowing.
                    </p>
                </>
            ),
        },
        {
            name: 'Tjerk',
            date: '4 juni 2023',
            content: (
                <>
                    <h2>Lekkere betaalbare maaltijden</h2>
                    <p className="text-[16px] leading-[24px]">
                        Goed eten voor een betaalbare prijs. Handig om in de vriezer te hebben.
                    </p>
                </>
            ),
        },
        {
            name: 'Natasha',
            date: '2 juni 2023',
            content: (
                <>
                    <h2>Heerlijke gezonde maaltijden voor in de vriezer</h2>
                    <p className="text-[16px] leading-[24px]">
                        Je proeft dat het door chefs bereid is. De karma curry is mijn favoriet.
                    </p>
                </>
            ),
        },
        {
            name: 'Mirjam',
            date: '23 mei 2023',
            content: (
                <>
                    <h2>Lekker veel groente</h2>
                    <p className="text-[16px] leading-[24px]">
                        Heerlijk! Meerdere keren al besteld en bevalt erg goed. Allemaal plantaardig en dus veel
                        groente, lekker gezond, een aanrader!
                    </p>
                </>
            ),
        },
        {
            name: 'Roger',
            date: '12 april 2023',
            content: (
                <>
                    <h2>Delicious vegan meals!</h2>
                    <p className="text-[16px] leading-[24px]">
                        {`Lot's of unique dishes to choose from, and all very different from one another. And healthy too,
                        lots of veggies.`}
                    </p>
                </>
            ),
        },
        {
            name: 'Sophie',
            date: '4 april 2023',
            content: (
                <>
                    <h2>Snelle bezorging en goede service</h2>
                    <p className="text-[16px] leading-[24px]">
                        Wij nemen de grote 2 persoons porties dan eten we gezellig allebei het zelfde.
                    </p>
                </>
            ),
        },
    ]

    const [paginationEl, paginationRef] = useSwiperRef<HTMLDivElement>()

    return (
        <>
            <Swiper
                modules={[Autoplay, Pagination]}
                speed={1000}
                loop
                autoplay={{
                    delay: 5000,
                }}
                className={classNames(`m-[0]`, { 'mt-12': inBlog }, { 'lg:max-w-[620px]': !inBlog })}
                slidesPerView="auto"
                spaceBetween={20}
                pagination={{
                    el: paginationEl,
                    type: 'bullets',
                    clickable: true,
                }}
            >
                {reviews.map((review, index) => {
                    return (
                        <SwiperSlide
                            className={classNames(
                                `rounded-10 flex flex-col bg-[#FFFAEC] h-fit p-[40px] w-full`,
                                { '': inBlog },
                                { 'lg:max-w-[620px] max-w-[350px]lg:max-w-[620px] max-w-[350px]': !inBlog }
                            )}
                            key={`swiper_${index}`}
                        >
                            <div className="mb-6 [&>h2]:mb-2 [&>h2]:text-[18px]">{review.content}</div>
                            <span className="text-[14px] text-dark/70">{review.name && `${review.name}`}</span>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            <div className="gap-10 flex-wrap-reverse lg:flex-row lg:flex-nowrap w-full justify-between items-center lg:max-w-[620px] z-20 flex mt-[20px] lg:mt-[16px]">
                <div
                    className={classNames(
                        ` [&>span]:!m-[0] [&>span]:transition-all [&>span]:duration-200 [&>.swiper-pagination-bullet-active]:bg-dark [&>.swiper-pagination-bullet-active]:w-[27px] [&>.swiper-pagination-bullet-active]:rounded-10 [&>span]:w-[10px] [&>span]:h-[10px]  flex gap-[10px] !w-fit`,
                        { 'mb-20': inBlog },
                        { 'm-auto': !inBlog }
                    )}
                    ref={paginationRef}
                />
            </div>
        </>
    )
}

export default ReviewSlider
