export const stores = [
    {
        address: 'Moerwijk 2',
        postal_code: '4826 HN',
        city: 'Breda',
        latitude: 51.6065868,
        longitude: 4.7893771,
    },
    {
        address: 'Schaarbroekerweg 14',
        postal_code: '6042 EJ',
        city: 'Roermond',
        latitude: 51.210988,
        longitude: 6.018032,
    },
    {
        address: 'Osdorpplein 469',
        postal_code: '1068 SZ',
        city: 'Amsterdam',
        latitude: 52.3591303,
        longitude: 4.8030169,
    },
    {
        address: 'Hanzestraat 411',
        postal_code: '6826 ML',
        city: 'Arnhem',
        latitude: 51.9832125,
        longitude: 5.949168,
    },
    {
        address: 'Bogaardplein 22',
        postal_code: '2284 DM',
        city: 'Rijswijk Zh',
        latitude: 52.0357158,
        longitude: 4.3155975,
    },
    {
        address: 'Gelderlandplein 47',
        postal_code: '1082 KZ',
        city: 'Amsterdam',
        latitude: 52.3310405,
        longitude: 4.8778357,
    },
    {
        address: 'De Savornin Lohmanplein 20',
        postal_code: '2566 AC',
        city: 'S-gravenhage',
        latitude: 52.0672417,
        longitude: 4.2455969,
    },
    {
        address: 'Spoelerstraat 6',
        postal_code: '7442 MC',
        city: 'Nijverdal',
        latitude: 52.3643689,
        longitude: 6.4659702,
    },
    {
        address: 'Vrieschgroenstraat 5',
        postal_code: '1503 MC',
        city: 'Zaandam',
        latitude: 52.4441897,
        longitude: 4.8376715,
    },
    {
        address: 'Sint Martinusplein 8',
        postal_code: '8161 CM',
        city: 'Epe',
        latitude: 52.349267,
        longitude: 5.985227,
    },
    {
        address: 'Piazza Center 74',
        postal_code: '4204 BS',
        city: 'Gorinchem',
        latitude: 51.8367656,
        longitude: 4.9619734,
    },
    {
        address: 'Martinus Nijhofflaan 7',
        postal_code: '2624 ER',
        city: 'Delft',
        latitude: 51.9964844,
        longitude: 4.3529711,
    },
    {
        address: 'Weigelia 48',
        postal_code: '2262 AB',
        city: 'Leidschendam',
        latitude: 52.0879995,
        longitude: 4.3828111,
    },
    {
        address: 'Siersteenlaan 464',
        postal_code: '9743 ES',
        city: 'Groningen',
        latitude: 53.2252708,
        longitude: 6.5286598,
    },
    {
        address: 'Hof van Holland 30',
        postal_code: '3332 EH',
        city: 'Zwijndrecht',
        latitude: 51.8229401,
        longitude: 4.6525953,
    },
    {
        address: 'Godsweerdersingel 74',
        postal_code: '6041 GM',
        city: 'Roermond',
        latitude: 51.1962164,
        longitude: 5.9947204,
    },
    {
        address: 'Sterrebos 2',
        postal_code: '5344 AM',
        city: 'Oss',
        latitude: 51.754471,
        longitude: 5.5116519,
    },
    {
        address: 'Zuidplein 60',
        postal_code: '3083 CW',
        city: 'Rotterdam',
        latitude: 51.8884505,
        longitude: 4.489522,
    },
    {
        address: 'Petuniatuin 12',
        postal_code: '2724 NA',
        city: 'Zoetermeer',
        latitude: 52.0634959,
        longitude: 4.5153367,
    },
    {
        address: 'Bisonspoor 1078',
        postal_code: '3605 KJ',
        city: 'Maarssen',
        latitude: 52.1348159,
        longitude: 5.0308473,
    },
    {
        address: 'Reigerhof 150',
        postal_code: '2914 KE',
        city: 'Nieuwerkerk Ad Ijssel',
        latitude: 51.9681476,
        longitude: 4.6194287,
    },
    {
        address: 'Rio de Janeiroplein 1',
        postal_code: '1334 HE',
        city: 'Almere',
        latitude: 52.3944939,
        longitude: 5.2808464,
    },
    {
        address: 'Kerkstraat 26',
        postal_code: '3891 EV',
        city: 'Zeewolde',
        latitude: 52.3303394,
        longitude: 5.5418374,
    },
    {
        address: 'Wade 10',
        postal_code: '8043 LS',
        city: 'Zwolle',
        latitude: 52.5333915,
        longitude: 6.0556674,
    },
    {
        address: 'Helperplein 4',
        postal_code: '9721 CX',
        city: 'Groningen',
        latitude: 53.1974554,
        longitude: 6.5793436,
    },
    {
        address: 'Passage 65',
        postal_code: '3431 LN',
        city: 'Nieuwegein',
        latitude: 52.028428,
        longitude: 5.080594,
    },
    {
        address: 'Wendelaarstraat 1',
        postal_code: '1814 GR',
        city: 'Alkmaar',
        latitude: 52.6198491,
        longitude: 4.7366374,
    },
    {
        address: 'Ackershof 53',
        postal_code: '2641 DZ',
        city: 'Pijnacker',
        latitude: 52.0194015,
        longitude: 4.4337006,
    },
    {
        address: 'Limburglaan 2',
        postal_code: '5652 AA',
        city: 'Eindhoven',
        latitude: 51.4353967,
        longitude: 5.4511038,
    },
    {
        address: 'Kronenburgpassage 86',
        postal_code: '6831 ER',
        city: 'Arnhem',
        latitude: 51.959375,
        longitude: 5.8972429,
    },
    {
        address: 'Winkelhof 29',
        postal_code: '2353 TS',
        city: 'Leiderdorp',
        latitude: 52.163131,
        longitude: 4.5334022,
    },
    {
        address: 'Centrumpassage 27',
        postal_code: '2903 HA',
        city: 'Capelle Aan Den Ijssel',
        latitude: 51.9321842,
        longitude: 4.589095,
    },
    {
        address: 'Thuvinestraat 33',
        postal_code: '6921 BB',
        city: 'Duiven',
        latitude: 51.9484808,
        longitude: 6.0205824,
    },
    {
        address: 'Oud-Bodegraafseweg 23',
        postal_code: '2411 HS',
        city: 'Bodegraven',
        latitude: 52.0837607,
        longitude: 4.7438813,
    },
    {
        address: 'Rondgang 20',
        postal_code: '9408 MH',
        city: 'Assen',
        latitude: 52.999755,
        longitude: 6.5133593,
    },
    {
        address: 'Ella Fitzgeraldplein 6',
        postal_code: '3543 EB',
        city: 'Utrecht',
        latitude: 52.101493,
        longitude: 5.044189,
    },
    {
        address: 'Hammarskjoldhof 64',
        postal_code: '3527HE',
        city: 'Utrecht',
        latitude: 52.0737484,
        longitude: 5.0944171,
    },
    {
        address: 'Pijmanstraat 18',
        postal_code: '8014 VW',
        city: 'Zwolle',
        latitude: 52.4834336,
        longitude: 6.1113638,
    },
    {
        address: 'Emiclaerhof 142',
        postal_code: '3823 ER',
        city: 'Amersfoort',
        latitude: 52.1866924,
        longitude: 5.3965769,
    },
    {
        address: 'Bijlmerplein 526',
        postal_code: '1102 DP',
        city: 'Amsterdam Zuidoost',
        latitude: 52.315739,
        longitude: 4.9544058,
    },
    {
        address: 'Zijdelwaardplein 84',
        postal_code: '1422 DN',
        city: 'Uithoorn',
        latitude: 52.246215,
        longitude: 4.8270899,
    },
    {
        address: 'Onderdoor 66',
        postal_code: '3995 DX',
        city: 'Houten',
        latitude: 52.033762,
        longitude: 5.1687846,
    },
    {
        address: 'Oudedijk 149',
        postal_code: '3061 AA',
        city: 'Rotterdam',
        latitude: 51.9274526,
        longitude: 4.5098161,
    },
    {
        address: 'Gildeweg 6',
        postal_code: '4383 NK',
        city: 'Vlissingen',
        latitude: 51.4555366,
        longitude: 3.5857637,
    },
    {
        address: 'Brusselsepoort 45',
        postal_code: '6216 CG',
        city: 'Maastricht',
        latitude: 50.850059,
        longitude: 5.6668183,
    },
    {
        address: 'Tijnjedyk 76',
        postal_code: '8936 AD',
        city: 'Leeuwarden',
        latitude: 53.1916144,
        longitude: 5.817685,
    },
    {
        address: 'St. Jacobslaan 61',
        postal_code: '6533 BP',
        city: 'Nijmegen',
        latitude: 51.8214953,
        longitude: 5.8519067,
    },
    {
        address: 'Triade 14',
        postal_code: '9401 ZH',
        city: 'Assen',
        latitude: 52.9977196,
        longitude: 6.5606752,
    },
    {
        address: 'Burg. D. Kooimanweg 16',
        postal_code: '1442 BZ',
        city: 'Purmerend',
        latitude: 52.509527,
        longitude: 4.9591788,
    },
    {
        address: 'Wethouder Sangersstraat 174',
        postal_code: '6191 NA',
        city: 'Beek Lb',
        latitude: 50.9500253,
        longitude: 5.7939749,
    },
    {
        address: 'Polsbroek 77',
        postal_code: '7201 BX',
        city: 'Zutphen',
        latitude: 52.1397763,
        longitude: 6.2027601,
    },
    {
        address: 'Jan Heijnsstraat 10',
        postal_code: '5041 GB',
        city: 'Tilburg',
        latitude: 51.5632282,
        longitude: 5.075204,
    },
    {
        address: 'Middenwaard 2',
        postal_code: '1703 SE',
        city: 'Heerhugowaard',
        latitude: 52.6627242,
        longitude: 4.8308595,
    },
    {
        address: 'Burg van Stamplein 270',
        postal_code: '2132 BH',
        city: 'Hoofddorp',
        latitude: 52.301616,
        longitude: 4.694038,
    },
    {
        address: 'de Loper 85',
        postal_code: '3137 DD',
        city: 'Vlaardingen',
        latitude: 51.9326413,
        longitude: 4.3455995,
    },
    {
        address: 'Middenmolenplein 64',
        postal_code: '2807 DX',
        city: 'Gouda',
        latitude: 52.0115647,
        longitude: 4.7438072,
    },
    {
        address: 'Hoefbladplein 4',
        postal_code: '2643 HA',
        city: 'Pijnacker',
        latitude: 52.0042712,
        longitude: 4.443462,
    },
    {
        address: 'Kiryat Onoplein 87',
        postal_code: '9203 KS',
        city: 'Drachten',
        latitude: 53.1010299,
        longitude: 6.0966685,
    },
    {
        address: 'Winkelcentrum Woensel 119',
        postal_code: '5625 AG',
        city: 'Eindhoven',
        latitude: 51.4680675,
        longitude: 5.4736339,
    },
    {
        address: 'Torenstraat 1',
        postal_code: '5701 SH',
        city: 'Helmond',
        latitude: 51.4827827,
        longitude: 5.6540775,
    },
    {
        address: 'Griendtsveenweg 20',
        postal_code: '7901 EA',
        city: 'Hoogeveen',
        latitude: 52.723296,
        longitude: 6.467901,
    },
    {
        address: 'Kinheim 305',
        postal_code: '3191 EG',
        city: 'Hoogvliet Rotterdam',
        latitude: 51.8650302,
        longitude: 4.355591,
    },
    {
        address: 'Pottenbakkerssingel 2',
        postal_code: '4331 NA',
        city: 'Middelburg',
        latitude: 51.4959529,
        longitude: 3.6078889,
    },
    {
        address: 'Zwanenveld 5505',
        postal_code: '6538 TZ',
        city: 'Nijmegen',
        latitude: 51.8233211,
        longitude: 5.7983716,
    },
    {
        address: 'Burchtplein 80',
        postal_code: '5341 VE',
        city: 'Oss',
        latitude: 51.7715189,
        longitude: 5.5263089,
    },
    {
        address: 'Elandstraat 160',
        postal_code: '2513 GW',
        city: 'S-gravenhage',
        latitude: 52.0805367,
        longitude: 4.2968201,
    },
    {
        address: 'Roelantdreef 41',
        postal_code: '3562 KC',
        city: 'Utrecht',
        latitude: 52.1129238,
        longitude: 5.1125126,
    },
    {
        address: 'Zeilmakerstraat 2',
        postal_code: '2222 AA',
        city: 'Katwijk Zh',
        latitude: 52.2022559,
        longitude: 4.4273182,
    },
    {
        address: 'Argonautenweg 1',
        postal_code: '3054 RP',
        city: 'Rotterdam',
        latitude: 51.9591858,
        longitude: 4.4944171,
    },
    {
        address: 'Bevrijdingsplein 50',
        postal_code: '2321 BX',
        city: 'Leiden',
        latitude: 2.1464488,
        longitude: 0.4763624,
    },
    {
        address: 'Vierhavensstraat 147',
        postal_code: '3029 BB',
        city: 'Rotterdam',
        latitude: 1.9095693,
        longitude: 0.4364135,
    },
    {
        address: 'Roda J.C. Ring 5',
        postal_code: '6466 NH',
        city: 'Kerkrade',
        latitude: 0.8573385,
        longitude: 0.0072461,
    },
    {
        address: 'Koningshoek 91001',
        postal_code: '3144 BA',
        city: 'Maassluis',
        latitude: 1.928191,
        longitude: 0.2390985,
    },
    {
        address: 'Rimsky-Korssakovweg 9',
        postal_code: '1323 LP',
        city: 'Almere',
        latitude: 52.3667925,
        longitude: 5.1902615,
    },
    {
        address: 'Westkade 48',
        postal_code: '4551 BV',
        city: 'Sas Van Gent',
        latitude: 51.2249842,
        longitude: 3.8014576,
    },
    {
        address: 'Glacisstraat 15',
        postal_code: '4615 AE',
        city: 'Bergen Op Zoom',
        latitude: 51.4880215,
        longitude: 4.291033,
    },
    {
        address: 'Dijkstraat 102',
        postal_code: '2675 AZ',
        city: 'Honselersdijk',
        latitude: 52.008441,
        longitude: 4.2295004,
    },
    {
        address: 'Verdilaan 4',
        postal_code: '2671 VX',
        city: 'Naaldwijk',
        latitude: 51.9932064,
        longitude: 4.2105783,
    },
    {
        address: 'Pampusweg 13',
        postal_code: '1398 PN',
        city: 'Muiden',
        latitude: 52.334115,
        longitude: 5.0217258,
    },
    {
        address: 'Keesomstraat 3',
        postal_code: '6717 AH',
        city: 'Ede Gld',
        latitude: 52.0305614,
        longitude: 5.6472699,
    },
    {
        address: 'Nijmeegseweg 20',
        postal_code: '5916 PT',
        city: 'Venlo',
        latitude: 51.3833199,
        longitude: 6.1832021,
    },
    {
        address: 'Molukkenstraat 308',
        postal_code: '1098 TW',
        city: 'Amsterdam',
        latitude: 52.3574634,
        longitude: 4.9397281,
    },
    {
        address: 'Koningin Julianaplein 10',
        postal_code: '1502 DV',
        city: 'Zaandam',
        latitude: 52.4543031,
        longitude: 4.8281447,
    },
    {
        address: 'Kraailandhof 106',
        postal_code: '3828 JM',
        city: 'Hoogland',
        latitude: 52.1870487,
        longitude: 5.3754335,
    },
    {
        address: 'Velperweg 47',
        postal_code: '6824 BG',
        city: 'Arnhem',
        latitude: 51.9886801,
        longitude: 5.9282502,
    },
    {
        address: 'Schapendoesweg 2',
        postal_code: '7312 EC',
        city: 'Apeldoorn',
        latitude: 52.20877,
        longitude: 5.9435333,
    },
    {
        address: 'Wesseler-nering 20',
        postal_code: '7544 JB',
        city: 'Enschede',
        latitude: 52.1917218,
        longitude: 6.8792162,
    },
    {
        address: 'Oranje Marieplein 8',
        postal_code: '6633 RL',
        city: 'Lent',
        latitude: 51.8647719,
        longitude: 5.8539047,
    },
    {
        address: 'Parkweide 14',
        postal_code: '6718 DJ',
        city: 'Ede Gld',
        latitude: 52.050739,
        longitude: 5.644245,
    },
    {
        address: 'Olivier van Noortstraat 32',
        postal_code: '1363 LB',
        city: 'Almere',
        latitude: 52.3520687,
        longitude: 5.1390448,
    },
    {
        address: 'De Arend 2',
        postal_code: '8265 NK',
        city: 'Kampen',
        latitude: 52.5336775,
        longitude: 5.9161766,
    },
    {
        address: 'Sophiapromenade 14',
        postal_code: '3344 PR',
        city: 'Hendrik-ido-ambacht',
        latitude: 51.835062,
        longitude: 4.6462719,
    },
    {
        address: 'Groene Kruisstraat 8',
        postal_code: '6049 BW',
        city: 'Herten',
        latitude: 51.1794195,
        longitude: 5.9631539,
    },
    {
        address: 'Molenhoekpassage 5',
        postal_code: '5242 CZ',
        city: 'Rosmalen',
        latitude: 51.7103089,
        longitude: 5.3664694,
    },
    {
        address: 'Runneboom 2',
        postal_code: '7232 CX',
        city: 'Warnsveld',
        latitude: 52.1349878,
        longitude: 6.2287691,
    },
    {
        address: 'Stationsplein 17',
        postal_code: '4561 GC',
        city: 'Hulst',
        latitude: 51.276593,
        longitude: 4.044433,
    },
    {
        address: 'Beneden Oosterdiep 165',
        postal_code: '9645 NT',
        city: 'Veendam',
        latitude: 53.1116264,
        longitude: 6.8852438,
    },
    {
        address: 'Burg Kruisingalaan 5',
        postal_code: '9801 BH',
        city: 'Zuidhorn',
        latitude: 53.2445191,
        longitude: 6.4100708,
    },
    {
        address: 'Einsteinstraat 103',
        postal_code: '1433 KJ',
        city: 'Kudelstaart',
        latitude: 52.2339628,
        longitude: 4.749103,
    },
    {
        address: 'Nico de Regtplein 5',
        postal_code: '3218 XW',
        city: 'Heenvliet',
        latitude: 51.8619202,
        longitude: 4.2362446,
    },
    {
        address: 'Markenlaan 3',
        postal_code: '1355 BA',
        city: 'Almere',
        latitude: 52.3420361,
        longitude: 5.2263063,
    },
    {
        address: 'Botplein 15',
        postal_code: '1317 SK',
        city: 'Almere',
        latitude: 52.3891351,
        longitude: 5.2317599,
    },
    {
        address: 'Warmoesstraat 37',
        postal_code: '9076 DL',
        city: 'St.-annaparochie',
        latitude: 53.274895,
        longitude: 5.6574075,
    },
    {
        address: 'Muiderbos 140',
        postal_code: '2134 SV',
        city: 'Hoofddorp',
        latitude: 52.314692,
        longitude: 4.659527,
    },
    {
        address: 'Watercipresstraat 15',
        postal_code: '1326 CM',
        city: 'Almere',
        latitude: 52.3758375,
        longitude: 5.243747,
    },
    {
        address: 'De Hoven 25',
        postal_code: '7122 BJ',
        city: 'Aalten',
        latitude: 51.9269074,
        longitude: 6.5795265,
    },
    {
        address: 'Strijpsestraat 137',
        postal_code: '5616 GM',
        city: 'Eindhoven',
        latitude: 51.4406652,
        longitude: 5.4531782,
    },
    {
        address: 'Haven Noord 1',
        postal_code: '7731 GZ',
        city: 'Ommen',
        latitude: 52.5251628,
        longitude: 6.4257783,
    },
    {
        address: 'Van Lenneplaan 99',
        postal_code: '9721 PE',
        city: 'Groningen',
        latitude: 53.1964167,
        longitude: 6.5679616,
    },
    {
        address: 'Fortunastraat 17',
        postal_code: '6846XZ',
        city: 'Arnhem',
        latitude: 51.9524359,
        longitude: 5.8461728,
    },
    {
        address: 'Middenburcht 14',
        postal_code: '3452 MT',
        city: 'Vleuten',
        latitude: 52.0930374,
        longitude: 5.0129433,
    },
    {
        address: 'Lombokstraat 2',
        postal_code: '1335 JN',
        city: 'Almere',
        latitude: 52.4021757,
        longitude: 5.2999325,
    },
    {
        address: 'Spoorwegstraat 1',
        postal_code: '2015 BL',
        city: 'Haarlem',
        latitude: 52.3869013,
        longitude: 4.6224706,
    },
    {
        address: 'Westerstraat 188',
        postal_code: '1601 AP',
        city: 'Enkhuizen',
        latitude: 52.7031397,
        longitude: 5.2827945,
    },
    {
        address: 'Overdiep 1',
        postal_code: '9902 DB',
        city: 'Appingedam',
        latitude: 53.318231,
        longitude: 6.859054,
    },
    {
        address: 'Rijksweg 16',
        postal_code: '9731 AB',
        city: 'Groningen',
        latitude: 53.2273902,
        longitude: 6.5995528,
    },
    {
        address: 'Frieswijkstraat 72',
        postal_code: '3862 BM',
        city: 'Nijkerk Gld',
        latitude: 52.2164515,
        longitude: 5.4779053,
    },
    {
        address: 'Operaplein 110',
        postal_code: '7323 EL',
        city: 'Apeldoorn',
        latitude: 52.2297954,
        longitude: 5.9872491,
    },
    {
        address: 'Winkelcentrum 19',
        postal_code: '6581 BV',
        city: 'Malden',
        latitude: 51.782045,
        longitude: 5.8537071,
    },
    {
        address: 'Larenseweg 102',
        postal_code: '7451 EN',
        city: 'Holten',
        latitude: 52.2711867,
        longitude: 6.4055758,
    },
    {
        address: 'Zaagmolenpad 16',
        postal_code: '7008 AJ',
        city: 'Doetinchem',
        latitude: 51.9703307,
        longitude: 6.2725051,
    },
    {
        address: 'Valburgseweg 20',
        postal_code: '6661 EV',
        city: 'Elst Gld',
        latitude: 51.9195102,
        longitude: 5.8389968,
    },
    {
        address: 'Middelzeelaan 4',
        postal_code: '8604 BH',
        city: 'Sneek',
        latitude: 53.0390846,
        longitude: 5.6780084,
    },
    {
        address: 'Sniederslaan 53',
        postal_code: '5531 EH',
        city: 'Bladel',
        latitude: 51.3678358,
        longitude: 5.2210694,
    },
]
