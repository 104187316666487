'use client'

import * as React from 'react'
import { forwardRef, useState } from 'react'
import { InputFieldProps } from './types'
import classNames from 'classnames'
import EyeIcon from '../../../icons/eyeIcon'
import get from 'lodash/get'
import Button from '../../Button'
import RightArrowIcon from '../../../icons/rightArrowIcon'

// eslint-disable-next-line react/display-name
const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
    ({ error, errors, className, required, label, rightIcon, placeholder, type = 'text', id, ...props }, ref) => {
        const [isShowingPassword, setIsShowingPassword] = useState<'text' | 'password'>('password')
        const errorMessage = error ? error : errors && props.name ? get(errors, props.name)?.message : null

        const toggleShowingPassword = () => {
            setIsShowingPassword(prevState => {
                if (prevState === 'password') {
                    return 'text'
                }
                return 'password'
            })
        }

        // @ts-ignore
        return (
            <div className="relative flex-col w-full">
                {label && (
                    <label htmlFor={id || props.name} className="label mb-2 block">
                        {label}
                        {required && <sup className="text-red-600">*</sup>}
                    </label>
                )}
                <div className="relative">
                    <input
                        id={id || props.name}
                        ref={ref}
                        className={classNames(
                            `input h-[52px] w-full px-3 py-4`,
                            {
                                'border-red-500 placeholder-red-700 focus:border-red-500 focus:ring-red-500':
                                    errorMessage,
                            },
                            className
                        )}
                        type={type === 'password' ? isShowingPassword : type}
                        placeholder={placeholder}
                        {...props}
                    />
                    {!!rightIcon && (
                        <div className="pointer-events-none absolute flex items-center justify-center h-[52px] top-0 right-0 px-3 py-4">
                            {rightIcon}
                        </div>
                    )}
                    {type === 'password' && (
                        <div
                            className="absolute flex items-center justify-center h-[52px] top-0 right-0 lg:hover:cursor-pointer px-3 py-4"
                            onClick={() => toggleShowingPassword()}
                        >
                            <EyeIcon />
                        </div>
                    )}
                </div>
                {/* @ts-ignore */}
                {errorMessage && <p className="error">{errorMessage}</p>}
            </div>
        )
    }
)

export default InputField
