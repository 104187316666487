'use client'

import React from 'react'
import { MediaCardProps } from './types'

const MediaCard = ({ name, image, link }: MediaCardProps) => {
    return (
        <a
            aria-label={`${name} logo`}
            href={link}
            className="[&>img]:max-h-[100%] [&>img]:object-contain flex items-center justify-center rounded-10 border-[1px] border-dark/20 py-[6px] px-[10px] md:py-[20px] md:px-[40px] h-[86px] w-[160px] md:h-[130px] md:w-[240px]"
        >
            {image}
        </a>
    )
}

export default MediaCard
