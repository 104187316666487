'use client'
import * as React from 'react'
import { useEffect, useRef } from 'react'
import { TrustpilotReviewsBigProps } from './types'
import Image from 'next/image'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper'
import useSwiperRef from '../../hooks/useSwiperRef'
import ReviewSlider from '../ReviewSlider'

const TrustpilotReviewsBig = ({ content }: TrustpilotReviewsBigProps) => {
    return (
        <div className="lg:px-0 lg:pb-20 container flex flex-col-reverse lg:flex-row overflow-hidden lg:overflow-visible">
            <div className="lg:pt-0 pt-20 lg:hidden w-full flex relative min-h-[400px]">
                <div className="rotate-6 relative pb-5 lg:pb-0 -left-12 md:[&>img]:w-[730px] [&>img]:w-[371px] [&>img]:h-[400px] [&>img]:rounded-[10px] overflow-hidden">
                    <Image
                        sizes="(max-width: 768px) 100vw, 50vw"
                        src="/images/dish-1.jpeg"
                        alt="dish"
                        width={371}
                        height={400}
                    />
                </div>
            </div>
            <div className="hidden lg:flex lg:w-6/12 ">
                <div className="hidden lg:block rotate-6 relative -left-60 z-10 [&>img]:w-[761px] [&>img]:h-[820px] rounded-[10px] overflow-hidden">
                    <Image
                        sizes="(max-width: 768px) 100vw, 50vw"
                        src="/images/dish-1.jpeg"
                        alt="dish"
                        width={761}
                        height={820}
                    />
                </div>
            </div>
            <div className="flex flex-col justify-center w-full lg:w-6/12 relative">
                <div
                    className="lg:mt-[0] mt-20 lg:max-w-[350px] w-full lg:[&>h2]:leading-[46px] [&>p]:mt-[20px] mb-[26px] lg:[&>p]:mt-5 lg:mb-12"
                    dangerouslySetInnerHTML={{ __html: String(content) }}
                />
                <ReviewSlider />
            </div>
        </div>
    )
}

export default TrustpilotReviewsBig
