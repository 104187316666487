'use client'
import dynamic from 'next/dynamic'
import { useInView } from 'react-intersection-observer'
import { stores } from '../../data/stores'
import { Autocomplete, LoadScript } from '@react-google-maps/api'
import InputField from '../FormFields/InputField'
import { useState } from 'react'

const DynamicCoreMap = dynamic(() => import('./CoreMap'), {
    suspense: true,
    ssr: false,
})

const StoreMap = () => {
    const { inView, ref } = useInView({ triggerOnce: true, fallbackInView: false })

    const [searchResult, setSearchResult] = useState<google.maps.places.Autocomplete>()
    const [place, setPlace] = useState<google.maps.places.PlaceResult>()

    const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
        setSearchResult(autocomplete)
    }

    const locationSelected = () => {
        if (searchResult) {
            const place = searchResult.getPlace()
            setPlace(place)
        }
    }

    return (
        <section ref={ref} id={`where-to-buy`} className="overflow-hidden">
            <div className="relative py-20 container px-0">
                <div className="bg-[#EAF8F4CC]/80 py-8 px-5 md:p-14 rounded-t-md md:rounded-lg w-full md:w-[70%] md:ml-24 -mb-8 md:-mb-14 relative z-10 backdrop-blur-md">
                    <h2 className="mb-3">Chef is altijd lekker dichtbij</h2>
                    <p>
                        Good Chef meenemen tijdens je dagelijkse boodschappen?
                        <br />
                        Check de winkelvinder voor een schap in de buurt.
                    </p>
                    <div className="bg-white mt-8 p-8 rounded-lg">
                        {/*<h3 className="mb-5">Zoek jouw chef</h3>*/}
                        <LoadScript
                            id="script-loader"
                            googleMapsApiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY || ''}
                            libraries={['places']}
                        >
                            <Autocomplete
                                onLoad={onLoad}
                                onPlaceChanged={locationSelected}
                                restrictions={{
                                    country: ['nl'],
                                }}
                                className="w-full"
                            >
                                <InputField placeholder={'Postcode of plaats'} name={'address'} className="w-1/2" />
                            </Autocomplete>
                        </LoadScript>
                    </div>
                </div>

                {inView ? (
                    <div className="w-full relative right-0 z-0">
                        <DynamicCoreMap stores={stores} place={place} />
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </section>
    )
}

export default StoreMap
