'use client'

import React from 'react'
import type { ProductCardProps } from './types'
import Highlight from '../Atoms/Highlight'
import Button from '../Button'
import EyeIcon from '../../icons/eyeIcon'
import classNames from 'classnames'
import { useAccount } from 'web/src/lib/context/account-context'
import { useProductActions } from 'web/src/lib/context/product-context'
import routes from 'web/src/lib/routes'
import Image from 'next/image'
import ChangeQuantity from '../ChangeQuantity'
import { useRouter } from 'next/navigation'
import { useStore } from 'web/src/lib/context/store-context'

const ProductCard = ({ inSlider, dense, highlight, imageProps }: ProductCardProps) => {
    const { customer } = useAccount()
    const { product } = useProductActions()
    const router = useRouter()
    const title = product.title
    const { setScrollPosition } = useStore()
    const subTitle = product.subtitle
    const link = `${routes.products}/${product.handle}`
    const image = product.thumbnail ? (
        <Image
            src={product.thumbnail}
            alt={product.title}
            width={dense ? 260 : 292}
            height={dense ? 210 : 292}
            {...imageProps}
        />
    ) : undefined

    const handleClick = (link: string) => {
        setScrollPosition(document.documentElement.scrollTop)
        router.push(link)
    }

    return (
        <div
            aria-label="Ga naar gerechten detail pagina"
            onClick={() => handleClick(link)}
            className={classNames(
                `group lg:hover:cursor-pointer rounded-10 flex grow bg-white`,
                { 'w-full md:block md:max-w-[calc(33.3333%-13.333px)] flex-row-reverse md:flex-col': !inSlider },
                { 'h-full flex-col': inSlider }
            )}
        >
            <div
                className={classNames('relative overflow-hidden rounded-t-10', {
                    '!rounded-l-none rounded-r-10 md:rounded-[10px]': !inSlider,
                })}
            >
                <div className="absolute left-2.5 md:left-[20px] top-2.5 md:top-[20px] z-10">
                    {highlight && <Highlight icon={highlight.icon} title={highlight.title} />}
                </div>
                <Button
                    aria-label={'Bekijk product'}
                    className="btn-round z-10 absolute right-2.5 md:right-5 top-2.5 md:top-5 bg-white shrink-0 flex items-center justify-center"
                >
                    <EyeIcon />
                </Button>
                <div
                    className={classNames(
                        `[&>img]:h-full lg:[&>img]:h-auto [&>img]:transition-all [&>img]:duration-200 lg:group-hover:[&>img]:scale-[1.2] w-full md:rounded-tl-10 overflow-hidden md:[&>img]:h-[calc(100%+10px)]`,
                        { 'h-full': !inSlider && !dense },
                        { 'md:h-[271px] h-[235px] [&>img]:h-full': inSlider && !dense },
                        { 'h-[200px]': dense }
                    )}
                >
                    {image}
                </div>
            </div>
            <div
                className={classNames(
                    `h-auto p-5 transition-all duration-200 justify-between rounded-[10px] flex flex-col bg-white shrink-0 grow z-10 w-full md:shadow -mt-2.5 min-h-[97px]`,
                    {
                        'basis-[60%] grow shrink-0 mt-0 md:-mt-2.5 rounded-r-none md:rounded-[10px] relative z-10':
                            !inSlider,
                    },
                    {
                        shadow: inSlider,
                    }
                )}
            >
                <div className={`flex flex-col`}>
                    <span className="mb-2 text-dark lg:hover:cursor-pointer font-bold text-[16px] leading-[19px]">
                        {title}
                    </span>
                    <span
                        className={classNames(
                            'lg:hover:cursor-pointer text-xs leading-tight font-normal text-dark/70',
                            {
                                'md:line-clamp-2': !customer,
                            },
                            {
                                'line-clamp-3 md:line-clamp-2': !!customer,
                            }
                        )}
                    >
                        {subTitle}
                    </span>
                </div>
                <ChangeQuantity />
            </div>
        </div>
    )
}

export default ProductCard
