import { Product } from 'web/src/types/medusa'

const getVariantId = (product: Product, portionSize?: string): string | undefined => {
    if (!portionSize || product.variants.length === 1) {
        return product.variants[0].id
    }

    return product.variants?.find(variant => variant.title === portionSize)?.id
}

export default getVariantId
