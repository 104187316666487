'use client'

import * as React from 'react'
import { SliderProps } from './types'
import { Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import classNames from 'classnames'

const SwiperComponent = ({ slides, content, inBlog, isBlogSlider }: SliderProps) => {
    return (
        <div className={classNames('relative', { 'overflow-hidden': !inBlog }, { 'my-10': inBlog })}>
            <div className={classNames('select-none', { container: !inBlog })}>
                {content}
                <Swiper
                    modules={[Scrollbar]}
                    className={classNames('!overflow-visible !mt-12', { '!mb-10 !w-full': isBlogSlider })}
                    slidesPerView="auto"
                    scrollbar={{
                        draggable: true,
                    }}
                >
                    {slides?.map((slide, index) => (
                        <SwiperSlide
                            key={index}
                            className={classNames(
                                'mr-2 lg:mr-5 flex h-auto',
                                { 'max-w-[260px] ': !isBlogSlider },
                                { 'max-w-[80vw] lg:max-w-[400px] ': isBlogSlider },
                            )}
                        >
                            {slide}
                        </SwiperSlide>
                    ))}
                    {inBlog && (
                        <>
                            <div
                                style={{
                                    background:
                                        'linear-gradient(270deg, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 0) 100%)',
                                }}
                                className="hidden lg:block pointer-events-none z-[50] transform-gpu translate-x-[100vw] absolute top-[0] right-[0] h-full w-[100vw]"
                            />
                            <div className="hidden lg:block z-[55] transform-gpu translate-x-[calc(100vw_+_130px)] absolute top-[0] right-[0] h-full w-[100vw]" />
                        </>
                    )}
                </Swiper>
            </div>
        </div>
    )
}

export default SwiperComponent
