import React, { FC, SVGAttributes } from "react"

const EyeIcon: FC<SVGAttributes<SVGElement>> = ({ ...props }) =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="13"
    fill="none"
    viewBox="0 0 17 13"
  >
    <path
      fill="#242424"
      d="M8.887 8.613c.591 0 1.158-.224 1.576-.623.418-.399.653-.94.653-1.503 0-.564-.235-1.105-.653-1.503a2.285 2.285 0 00-1.576-.623c-.591 0-1.158.224-1.576.623-.418.398-.653.94-.653 1.503 0 .564.235 1.104.653 1.503.418.399.985.623 1.576.623z"
    />
    <path
      fill="#242424"
      fillRule="evenodd"
      d="M.953 6.095C2.06 2.927 5.193.641 8.888.641c3.693 0 6.825 2.284 7.932 5.45.09.256.09.532 0 .788-1.105 3.168-4.24 5.454-7.934 5.454-3.693 0-6.826-2.284-7.932-5.45a1.194 1.194 0 010-.788zm11.835.392c0 .987-.411 1.933-1.143 2.63a4 4 0 01-2.758 1.09 4 4 0 01-2.759-1.09 3.635 3.635 0 01-1.142-2.63c0-.987.41-1.933 1.142-2.63a4 4 0 012.759-1.09 4 4 0 012.758 1.09 3.635 3.635 0 011.143 2.63z"
      clipRule="evenodd"
    />
  </svg>

export default EyeIcon
