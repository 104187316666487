import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/ui/components/Atoms/SwiperText/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/ui/components/Faq/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/ui/components/MealSlider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/ui/components/MediaSlider/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/ui/components/StoreMap/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/ui/components/TrustpilotReviewsBig/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/ui/layouts/MainLayout/index.tsx");
